import React from 'react';
import {
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { IUser } from '../../../shared/interfaces/IUser.interface';
import { maskedValue } from "../../../shared/util/util";
import { Controller, useFormContext } from 'react-hook-form';
import { emailRegex } from '../../../shared/util/constants';

export interface IUserDetailsProps {
  setUser: React.Dispatch<React.SetStateAction<any>>
  user: IUser;
  emailError: string;
}

export function RegisterUserDetails({ user, emailError }: IUserDetailsProps) {
  const { control, formState: { errors }, setValue } = useFormContext();
  const requiredErrorMessage = "This field is required";

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="justify">First Name*</Typography>
        <Controller
          name="first_name"
          defaultValue={user?.first_name}
          rules={{ required: requiredErrorMessage }}
          control={control}
          render={({ field }) =>
            <TextField
              error={!!errors.first_name}
              helperText={!!errors.first_name && errors.first_name?.message}
              placeholder="Jane"
              size="small"
              fullWidth
              variant="outlined"
              defaultValue={user?.first_name}
              InputProps={{ style: { fontSize: 16 } }}
              inputProps={{ tabIndex: 1 }}
              {...field}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="justify">Last Name*</Typography>
        <Controller
          name="last_name"
          control={control}
          defaultValue={user?.last_name || ""}
          rules={{ required: requiredErrorMessage }}
          render={({ field }) =>
            <TextField
              placeholder="Doe"
              size="small"
              fullWidth
              variant="outlined"
              error={!!errors.last_name}
              helperText={!!errors.last_name && errors.last_name?.message}
              InputProps={{ style: { fontSize: 16 } }}
              inputProps={{ tabIndex: 2 }}
              {...field}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="justify">Email*</Typography>
        <Controller
          name="email"
          defaultValue={user?.email || ""}
          rules={{ required: requiredErrorMessage, pattern: { value: emailRegex, message: "Must be a valid email address" } }}
          control={control}
          render={({ field }) =>
            <TextField
              placeholder="jdoe@gmail.com"
              size="small"
              fullWidth
              variant="outlined"
              error={!!errors.email || !!emailError}
              helperText={emailError ? emailError : (!!errors.email && errors.email?.message)}
              InputProps={{ style: { fontSize: 16 } }}
              inputProps={{ tabIndex: 5 }}
              {...field}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="justify">Role/Title*</Typography>
        <Controller
          name="role_title"
          defaultValue={user?.role_title || ""}
          rules={{ required: requiredErrorMessage }}
          control={control}
          render={({ field }) =>
            <TextField
              placeholder="Enter Role/Title"
              size="small"
              fullWidth
              variant="outlined"
              error={!!errors.role_title}
              helperText={!!errors.role_title && errors.role_title?.message}
              InputProps={{ style: { fontSize: 16 } }}
              inputProps={{ tabIndex: 4 }}
              {...field}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" align="justify">Phone*</Typography>
        <Controller
          name="phone"
          control={control}
          defaultValue={user?.phone}
          rules={{ required: requiredErrorMessage }}
          render={({ field }) =>
            <TextField
              placeholder="___ - ___ - ____"
              size="small"
              fullWidth
              variant="outlined"
              error={!!errors.phone}
              helperText={!!errors.phone && errors.phone?.message}
              InputProps={{ style: { fontSize: 16 } }}
              inputProps={{ tabIndex: 6 }}
              {...field}
              onChange={(e) => field.onChange(() => {
                let masked: string = maskedValue('000-000-0000', e.target.value)
                setValue(field.name, masked)
                return masked
              })}
            />
          }
        />
      </Grid>
    </Grid>
  )
}