// mui components
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { IProfile } from '../../../interfaces/IViewProfile.interface';
import { JFProfileImage, JFBanner } from '../../../../common/components';
import { FocusIcon } from '../../legacy-ui';
import bipocImage from '../../../../images/bipoc.svg';


type IHeaderProps = {
  entity: Pick<IProfile, 'name' | 'focuses' | 'bipoc_led' | 'hero' | 'logo_url' | 'logo'> & {
    summary?: string;
  }
}

export const Header = ({ entity }: IHeaderProps) => {
  const { name, focuses, bipoc_led, summary } = entity
  const bannerImage = entity?.hero?.url;
  const logoImage = entity?.logo_url || entity?.logo?.url;

  return (
    <Grid container marginBottom={5}>
      <Grid container item sm={12} lg={12}>
        <JFBanner
          height={220}
          hasSrc={!!bannerImage}
          src={bannerImage ?? '/images/banner_default.svg'}
          alt="organization banner"
        />
      </Grid>
      <Grid container item>
        <Grid item xs="auto">
          <JFProfileImage
            alt="Organization Logo"
            hasSrc={!!logoImage}
            marginTop={-100}
            objectFit='contain'
            size={200}
            src={logoImage || '/images/profile_default.svg'}
          />
        </Grid>
        <Grid item container xs={12} md spacing={4} paddingTop={3}>
          <Grid item container xs alignContent="center" spacing={1}>
            <Grid item display="flex" gap={2} alignItems="center">
              <Typography variant="h1" fontSize={28} fontWeight={600}>
                {name}
              </Typography>
              { bipoc_led ? (
              <Tooltip title="BIPOC-led">
                <Box
                  component="img"
                  sx={{ height: '28px' }}
                  src={bipocImage}
                  alt="bipoc led logo"
                />
              </Tooltip>
              ) : null }
              {!!focuses?.length && (
                <Box>
                  { focuses
                    .toSorted((a: any, b: any) => a.FunderFocus?.order - b.FunderFocus?.order)
                    .map((focus: any) => (
                      <FocusIcon key={focus.id} focus={focus.id} size="23px" />
                  ))}
                </Box>
              )}
            </Grid>
            {summary ? (
            <Grid container item xs={12}>
              <Typography variant="body1" fontSize={16} fontWeight={400}>
                {summary}
              </Typography>
            </Grid>
            ): null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};