import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { Card } from '../../../common/components';
import {
  FundButton,
  FundersAvatarGroup,
  ProfileCardDescription,
  ProfileCardDescriptionWrapper,
  ProfileCardDividiver,
  ProfileCardFocusesWrapper,
  ProfileCardTitle,
  ProfileCardTitleAvatar,
  ProfileCardWrapper
} from './ProfileCard.styles';
import { useHistory } from 'react-router';
import { useCallback, useState } from 'react';
import { BookmarkService } from '../../services/api-service/bookmark.service';
import { BookmarkEntityType } from '../../enums/bookmark.enum';
import { useFunderContext } from '../../../funder/providers/UserProvider';
import { useMessagingContext } from '../modals/Notification';
import { BookmarkIcon } from '../../../funder/components/app/navbar/icons';
import { EProfileType } from '../../enums/profile.enum';
import { IProfile } from '../../interfaces/IProfile';
import { formatUserName, getProfileRedirectUri } from '../../util/util';
import { FocusIcon, Tooltip } from '../legacy-ui';
import bipoc from '../../../images/bipoc.svg';
import { BipocLed } from '../../enums/bipoc.enum';
import ProfileTypeTag, { getProfileTypes } from '../../../common/components/ProfileTypeTag/ProfileTypeTag';
import { htmlToText } from '../../util/htmlToText';

interface ProfileCardProps {
  profile: IProfile;
  profileType: EProfileType;
  isAdmin: boolean;
  customId?: string;
  fundEventName?: string;
  cardEventName?: string;
  showFunders?: boolean;
  openDetails?: () => void;
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  profile,
  profileType,
  isAdmin,
  customId,
  fundEventName,
  cardEventName,
  openDetails,
  showFunders = true
}) => {
  const isGrantee = profileType === EProfileType.GRANTEE;
  const isPublished = isGrantee ? profile.isPublished : profile.funder?.isPublished;
  const cardPaddingLeft = 3;
  const cardPaddingTitle = cardPaddingLeft - 1;

  const history = useHistory();
  const { refreshBookmarkCount, funder: loginFunder, user } = useFunderContext();
  const {
    alertState: { showAlert }
  } = useMessagingContext();
  const [bookmarkId, setBookmarkId] = useState<number | undefined>(
    isGrantee ? profile.granteeBookmarks?.[0]?.id : profile.funder?.funderBookmarks?.[0]?.id
  );

  const navigateToGrantee = useCallback((eventName?: string) => {
    const id = isGrantee ? profile.id : profile.funder?.id!;
    
    if (user && eventName && isPublished) {
      gtag(
        'event',
        eventName,
        {
          profile_id: id,
          user_id: user.id,
          user_name: formatUserName(user)
        }
      );
    }
    if (openDetails) {      
      openDetails();
    }
    else {
      const redirectUri = getProfileRedirectUri(id, isGrantee, isAdmin);
      window.open(redirectUri, '_blank');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, profile.id, history]);

  const bookmarkAlert = (bookmarkToggle: boolean) => {
    if (bookmarkToggle) return showAlert({ title: 'Bookmark Removed!', severity: 'info' });
    else return showAlert({ title: 'Bookmark Added!', severity: 'success' });
  };

  
  const updateBookmark = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    const isBookmarked = !!bookmarkId;
    setBookmarkId(-1);

    try {
      if (isBookmarked) {
        await BookmarkService.delete(bookmarkId);
        setBookmarkId(undefined);
      } else {
        const updatedBookmark = await BookmarkService.create({
          bookmark_target_id: isGrantee ? profile.id : profile.funder!.id,
          bookmark_type: isGrantee ? BookmarkEntityType.GRANTEE : BookmarkEntityType.FUNDER
        });
        setBookmarkId(updatedBookmark.id);
      }
      bookmarkAlert(!!bookmarkId);
      refreshBookmarkCount();
    } catch (error) {
      showAlert({ title: 'Error while trying to bookmark', severity: 'error' });
      setBookmarkId(undefined);
    }
  };

  const fundsText = (count: number) =>
    `${count} Funder${count === 1 ? '' : 's'}`;




  return (
    <Card
      status="none"
      paddingX={0}
      paddingY={15}
      onClick={(isPublished  || openDetails) ? () => navigateToGrantee(cardEventName) : undefined}
    >
      <ProfileCardWrapper container>
        <Grid item container xs={12} paddingBottom={1} paddingX={cardPaddingTitle}>
          <Grid item xs display="flex" alignItems="center">
            <ProfileCardTitleAvatar
              src={profile.logoUrl}
              alt={profile.name}
            >
              {profile.name.charAt(0).toUpperCase()}
            </ProfileCardTitleAvatar>
            {/* here */}
            <Box ml={1} display="flex" flexDirection="column">
              <ProfileCardTitle fontSize={18} fontWeight={600} title={profile.name}>
                {profile.name}
              </ProfileCardTitle>
              <Box display="flex" flexWrap="wrap" mt={1}>
                <ProfileTypeTag types={getProfileTypes(profile)} />
          
              </Box>
            </Box>
          </Grid>
          {!!isPublished && loginFunder?.id !== profile.funder?.id ? (
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="end">
              <Tooltip
                title={!!bookmarkId ? 'Remove item from Bookmarks' : 'Add item to Bookmarks'}
              >
                <IconButton
                  size="small"
                  onClick={updateBookmark}
                  disabled={bookmarkId === -1}
                  sx={{ padding: 0 }}
                >
                  <BookmarkIcon
                    size={24}
                    filled={!!bookmarkId && bookmarkId > 0}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
        <ProfileCardDescriptionWrapper item container xs={12} paddingLeft={cardPaddingLeft} paddingRight={cardPaddingTitle}>
          <ProfileCardDescription>{htmlToText(profile?.description) || ''}</ProfileCardDescription>
        </ProfileCardDescriptionWrapper>
        <ProfileCardFocusesWrapper item container xs={12} ml={cardPaddingLeft - 0.5} alignItems="center">
          <Grid item container xs="auto" height={34}>
            {profile?.granteeFocuses?.map((granteeFocus) => (
              <FocusIcon
                size={24}
                key={`${profile.id}-${granteeFocus.focus.id}`}
                focus={granteeFocus.focus.id}
              />
            ))}
          </Grid>
          {profile?.bipocLed === BipocLed.YES ? (
            <Grid item container xs>
              <Tooltip title="BIPOC-led">
                <IconButton size="small">
                  <Box
                    component="img"
                    sx={{ height: '32px', width: '32px' }}
                    src={bipoc}
                    alt="bipoc led logo"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        </ProfileCardFocusesWrapper>
        { showFunders && (
          <>
            <ProfileCardDividiver sx={{ marginLeft: cardPaddingLeft, marginY: 1 }} />
            <Grid item container xs={12} paddingLeft={cardPaddingLeft} paddingRight={cardPaddingTitle}>
              <Grid item xs={12} mb={1} minHeight={20}>
                <Typography fontWeight={500} fontSize={14} color="black !important">
                  {fundsText(profile.fundedBy?.length || 0)}
                </Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={8} display="flex" flexDirection="row" alignItems="center" gap={1}>
                  <FundersAvatarGroup
                    total={profile.fundedBy?.length || 0}
                    max={4}
                    spacing={-5}
                  >
                    {profile.fundedBy ? profile.fundedBy.slice(0, 3).map((fundedBy) => (
                      <Tooltip title={fundedBy.details.name} key={`${profile.id}-${fundedBy.id}`}>
                        <Avatar
                          src={fundedBy.logo?.url}
                          alt={fundedBy.details.name}
                        >
                          {fundedBy.details.name.charAt(0).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    )) : <Box className="Box avatar" />}
                  </FundersAvatarGroup>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="flex-end">
                  {isPublished ? (
                    <FundButton id={customId ?? 'fund-button'} onClick={() => navigateToGrantee(fundEventName)}>
                      View
                    </FundButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </ProfileCardWrapper>
    </Card>
  );
};
