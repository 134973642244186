import DOMPurify from 'dompurify';
import styled from 'styled-components';
import { cleanIndentHTML } from '../../../shared/util/util';

interface IJFReadOnlyTextFieldProps {
  value?: string;
  isHtml?: boolean;
  dataTestId?: string;
}

const StyledDiv = styled.div`
  white-space: normal;
  font-size: 16px;

  p {
    margin: 0px 0px;
  }
`;

const JFReadOnlyTextField = (props: IJFReadOnlyTextFieldProps) => {
  const { value = "", isHtml = false, dataTestId = "" } = props;

  const htmlValue = value ? DOMPurify.sanitize(value, { ADD_ATTR: ['target'] }) : ''
  const processedHtmlValue = cleanIndentHTML(htmlValue);


  if (isHtml) {
    return (
      <StyledDiv data-testid={dataTestId}
        dangerouslySetInnerHTML={{
          __html: processedHtmlValue
        }}
      />
    );
  }

  return (
    <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>{value}</div>
  );
};

export default JFReadOnlyTextField;

